<template>
    <div id="searchPage">
      <TopSearch
        v-model="name"
        placeholder="動画検索"
        btnText="キャンセル"
        @click-right="$router.back()"
      ></TopSearch>
      <div class="container">
        <div v-if="name" class="section section-2">
          <Empty
            v-if="detail.totalCount===0"
            tip="関連コンテンツはありません"
          ></Empty>
          <van-list
            v-else
            :finished="detail.finished"
            v-model="detail.loading"
            @load="getDetail"
            loading-text="ローディング..."
            offset="30"
          >
            <van-row class="list" type="flex">
              <van-col
                v-for="(item, index) in detail.list"
                :key="index"
                :span="span"
              >
                <router-link
                  :to="{name: 'Detail',query: { id: item.id } }"
                  class="list-item"
                >
                  <ListItem2 :detail="item"></ListItem2>
                </router-link>
              </van-col>
            </van-row>
          </van-list>
        </div>
        <template v-else>
          <div class="section section-1">
            <div class="section-title">
              <div class="left">人気</div>
            </div>
            <div class="section-content">
              <ul class="list">
                <li
                  class="list-item"
                  v-for="(item, index) in hotMessageData"
                  :key="index"
                  @click="onSearch(item)"
                >
                  {{item}}
                </li>
              </ul>
            </div>
          </div>
          <div class="section section-1" v-if="searchHistory.length">
            <div class="section-title">
              <div class="left">歴史</div>
              <div class="right pointer" @click="onDelete">
                <i class="icon icon-clear"></i>
                <span>クリア</span>
              </div>
            </div>
            <div class="section-content">
              <ul class="list">
                <li
                  class="list-item"
                  v-for="(item, index) in searchHistory"
                  :key="index"
                  @click="onSearch(item)"
                >
                  {{item}}
                </li>
              </ul>
            </div>
          </div>
        </template>
      </div>
    </div>
</template>

<script>
import { TopSearch, Empty, ListItem2 } from '@/components';
import { mapGetters, mapActions } from 'vuex';
export default {
  name: 'Search',
  components: {
    TopSearch,
    Empty,
    ListItem2
  },
  data () {
    return {
      name: '',
      searchHistory: [],
      detail: {
        pageNo: 1,
        list: [],
        totalCount: '',
        pageSize: 10,
        totalPage: 1,
        finished: false,
        loading: false
      },
      span: 24,
      hotMessageData: []
    };
  },
  created () {
    this.hotMessage();
  },
  computed: {
    ...mapGetters(['htmlWidth', 'keepAliveInclude'])
  },
  beforeRouteLeave (to, form, next) {
    const { name } = form;
    if (to.name !== 'Detail') {
      this.removeKeepAliveInclude(name);
    }
    next();
  },
  mounted () {
    this.searchHistory = JSON.parse(localStorage.getItem('searchHistory')) || [];
    this.onResize();
  },
  watch: {
    htmlWidth () {
      this.onResize();
    },
    name (newVal) {
      if (newVal) {
        const { searchHistory } = this;
        const index = searchHistory.indexOf(newVal);
        if (index > -1) {
          searchHistory.splice(index, 1);
        }
        searchHistory.unshift(newVal);
        if (searchHistory.length > 8) {
          searchHistory.pop();
        }
        localStorage.setItem('searchHistory', JSON.stringify(searchHistory));
        this.init();
      }
    }
  },
  methods: {
    ...mapActions(['removeKeepAliveInclude']),
    init () {
      this.detail = {
        pageNo: 1,
        list: [],
        totalCount: '',
        pageSize: 20,
        finished: false,
        loading: false
      };
    },

    /* 获取搜索视频数据 */
    getDetail () {
      const { name, detail } = this;
      const { pageNo, pageSize } = detail;
      this.$http.get('homePage/videoSearchBox', {
        params: {
          pageNo,
          pageSize,
          videoName: name
        }
      }).then((res) => {
        const { isHasNext, nextPage, result, totalCount } = res.data || {};
        detail.list.push(...result);
        detail.finished = !isHasNext;
        detail.loading = false;
        detail.pageNo = nextPage;
        detail.totalCount = totalCount;
      });
    },
    // 查询
    onSearch (value) {
      this.name = value;
    },

    /* 获取热门词数据 */
    hotMessage () {
      const { $http } = this;
      this.hotMessage = $http.get('homePage/hotSearch').then((res) => {
        this.hotMessageData = res.data.list || {};
      });
    },
    onDelete (item) {
      const { $dialog, $utils } = this;
      $dialog.confirm({
        title: 'ご注意',
        message: 'このレコードを削除しますか？',
        confirmButtonText: 'を選択します',
        cancelButtonText: 'キャンセル',
        closeOnClickOverlay: true
      }).then(() => {
        this.searchHistory = [];
        localStorage.removeItem('searchHistory');
        $utils.successToast({
          message: '削除に成功しました'
        });
      }).catch(() => {});
    },
    onResize () {
      const { htmlWidth } = this;
      this.span = 24;
      if (htmlWidth > 1200) {
        this.span = 8;
      } else if (htmlWidth > 992) {
        this.span = 12;
      }
    }
  }
};
</script>

<style scoped lang="less">
  #searchPage {
    display: flex;
    flex-direction: column;
    height: 100%;

    .container {
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;

      .section {
        .section-title {
          justify-content: space-between;
          padding: 30px 28px 36px;

          &,
          .right {
            display: flex;
            align-items: center;
          }

          .left {
            font-size: 30px;
            line-height: 40px;
          }

          .right {
            font-size: 24px;
            line-height: 32px;

            .icon {
              width: 32px;
              height: 32px;
              margin-right: 12px;

              &.icon-clear {
                background-image: url("~@/assets/images/icon_clear_01.png");
              }
            }
          }
        }

        &.section-1 {
          &:first-child {
            padding-top: 12px;
          }

          .section-content {
            .list {
              display: flex;
              flex-wrap: wrap;
              padding-right: 14px;
              padding-left: 14px;

              .list-item {
                padding: 10px 32px;
                margin: 0 14px 28px;
                font-size: 24px;
                line-height: 36px;
                cursor: pointer;
                background-color: #2d2e2e;
                border-radius: 32px;
              }
            }
          }
        }

        &.section-2 {
          .list {
            .list-item {
              position: relative;
              width: 100%;
              padding: 28px;
              cursor: pointer;

              &::after {
                position: absolute;
                bottom: 0;
                left: 28px;
                width: calc(100% - 56px);
                height: 1px;
                content: '';
                background-color: rgba(255, 255, 255, 0.05);
              }

              .icon-del {
                width: 36px;
                height: 36px;
                background-image: url("~@/assets/images/icon_delete_01.png");
              }
            }
          }
        }
      }
    }
  }
</style>